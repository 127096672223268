<template>
  <brand-form
    ref="brand-form"
    :is-create-form="true"
  />
</template>

<script>

import BrandForm from '../components/BrandsForm.vue'

export default {
  name: 'BrandCreate',
  inject: ['brandModuleName'],
  components: {
    BrandForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['brand-form']) {
      this.$refs['brand-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    const initialData = {
      is_active: false,
    }

    this.$store.commit(`${this.brandModuleName}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_BRAND_FORM_CLONE`, initialData)
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME_CLONE,
    }
  },
}
</script>
